import {
  DocumentApi,
  EnumDisplayIbanAccountType,
  EnumDocumentStatus,
  EnumDocumentType,
  InvoicingSettingsApi,
} from '@blank/api'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { convertNullToUndefined } from 'modules/common/lib/helpers/type'
import { ApiError } from 'modules/common/types/error'
import { decimalPrice } from 'modules/invoicing/lib/format/price'
import {
  DocumentInformationFormData,
  DocumentSettingsFormData,
} from '../components/documents/DocumentFormContainer/types'
import { DocumentValidationFormData } from '../hooks/document/documentValidation/useDocumentValidationForm'
import { DocumentLineFormData } from '../hooks/document/form/useDocumentLineForm'
import {
  DocumentItemPayload,
  DocumentRow,
  DOCUMENT_TYPE_SLUGS,
  INVOICING_SETTINGS_DELAY_PROPERTY_NAME,
  PostDocumentPayload,
  PostDocumentPreviewPayload,
  PostValidateDocumentPayload,
} from '../types/document'
import { getCustomerName, mapCustomerToSelectCustomerField } from './customer'
import { formatDocumentNumber } from './format/documentNumber'

export const isDocumentNumberAlreadyTakenError = (
  error: AxiosError<ApiError>
) => error.response?.data?.error?.message === 'document_number_already_taken'

export const prepareDocumentListTable = (
  documentList: DocumentApi[]
): DocumentRow[] => {
  return documentList.map(prepareDocumentRow)
}

export const prepareDocumentRow = ({
  id,
  documentNumber,
  title,
  createdAt,
  dueDate,
  isLate,
  status,
  totalWithoutVAT,
  customer,
  type,
}: DocumentApi): DocumentRow => ({
  id,
  documentNumber: formatDocumentNumber(documentNumber),
  title,
  customerName: getCustomerName(customer),
  createdAt: dayjs(createdAt).format('DD/MM/YYYY'),
  dueDate: dayjs(dueDate).format('DD/MM/YYYY'),
  isLate,
  status,
  totalWithoutVAT: decimalPrice(totalWithoutVAT),
  type,
})

interface MapDocumentSettingsFormToPostDocumentPayloadPartProps {
  documentSettingsFormData: DocumentSettingsFormData
  delay: DocumentInformationFormData['delay']
  type: EnumDocumentType
}

export const mapDocumentSettingsFormToPostDocumentPayloadPart = ({
  documentSettingsFormData: {
    accountType,
    additionalMention,
    displayIban,
    footer,
    terms,
  },
  delay,
  type,
}: MapDocumentSettingsFormToPostDocumentPayloadPartProps): PostDocumentPayload['settings'] => {
  switch (type) {
    case EnumDocumentType.ESTIMATE:
      return {
        estimateAcceptationDays: convertNullToUndefined(delay),
        estimateAdditionnalMention: additionalMention,
        estimateAcceptationTerms: terms,
        footer,
      }
    case EnumDocumentType.INVOICE:
      return {
        ...(displayIban !== null && {
          displayIBAN: {
            type: accountType,
            display: displayIban,
          },
        }),
        footer,
        invoicePaymentDays: convertNullToUndefined(delay),
        invoiceAdditionnalMention: additionalMention,
        invoicePaymentTerms: terms,
      }
  }
}

interface MapDocumentFormToPostDocumentPreviewPayloadProps {
  documentInformationFormData: DocumentInformationFormData
  documentSettingsFormData: DocumentSettingsFormData
  documentType: EnumDocumentType
}

export const mapDocumentFormToPostDocumentPreviewPayload = ({
  documentInformationFormData: {
    customer,
    title,
    documentNumber,
    items,
    delay,
    percentageDiscount,
    fiscalStampPrice,
    pensionContribution,
    withHolding,
  },
  documentSettingsFormData,
  documentType,
}: MapDocumentFormToPostDocumentPreviewPayloadProps): PostDocumentPreviewPayload => ({
  type: documentType,
  customerId: customer?.value,
  discountRate: percentageDiscount ? percentageDiscount / 100 : 0,
  ...(title && { title }),
  ...(documentNumber && { documentNumber }),
  ...(fiscalStampPrice && {
    fiscalStampPrice: Math.round(fiscalStampPrice * 100), // Convert euros in cents,
  }),
  ...(pensionContribution?.code && {
    pensionContribution: {
      code: pensionContribution.code.value,
      rate: pensionContribution.percentage / 100,
    },
  }),
  ...(withHolding?.code && {
    withHolding: {
      code: withHolding.code.value,
      rate: withHolding.percentage / 100,
    },
  }),
  items: (items ?? []).map(({ unitPriceWithoutVAT, ...rest }) => ({
    unitPriceWithoutVAT: Math.round(unitPriceWithoutVAT * 100), // Convert euros in cents
    ...rest,
  })),
  settings: mapDocumentSettingsFormToPostDocumentPayloadPart({
    documentSettingsFormData,
    delay,
    type: documentType,
  }),
})

interface MapDocumentFormToPostDocumentPayloadProps {
  documentInformationFormData: DocumentInformationFormData
  documentSettingsFormData: DocumentSettingsFormData
  documentType: EnumDocumentType
  documentStatus: EnumDocumentStatus
}

export const mapDocumentFormToPostDocumentPayload = ({
  documentInformationFormData,
  documentSettingsFormData,
  documentStatus,
  documentType,
}: MapDocumentFormToPostDocumentPayloadProps): PostDocumentPayload => ({
  ...mapDocumentFormToPostDocumentPreviewPayload({
    documentInformationFormData,
    documentSettingsFormData,
    documentType,
  }),
  status: documentStatus,
})

interface MapDocumentToDocumentInformationFormProps {
  document: DocumentApi
  defaultDocumentNumber?: string
}

export const mapDocumentToDocumentInformationForm = ({
  document: {
    customer,
    documentNumber,
    title,
    type,
    items,
    discountRate,
    withHolding,
    pensionContribution,
    fiscalStampPrice,
    settings,
  },
  defaultDocumentNumber = '',
}: MapDocumentToDocumentInformationFormProps): DocumentInformationFormData => ({
  title,
  customer: customer ? mapCustomerToSelectCustomerField(customer) : null,
  documentNumber: documentNumber ?? defaultDocumentNumber,
  delay: settings[INVOICING_SETTINGS_DELAY_PROPERTY_NAME[type]] ?? null,
  percentageDiscount: discountRate ? discountRate * 100 : null,
  fiscalStampPrice: fiscalStampPrice ? fiscalStampPrice / 100 : null, // Convert cents in euros,
  pensionContribution: pensionContribution
    ? {
        code: { value: pensionContribution.code },
        percentage: pensionContribution.rate * 100,
      }
    : null,
  withHolding: withHolding
    ? {
        code: { value: withHolding.code },
        percentage: withHolding.rate * 100,
      }
    : null,
  items: items?.map(
    ({
      currency,
      description,
      name,
      quantity,
      unit,
      unitPriceWithoutVAT,
      VATRate,
    }) => ({
      currency,
      description,
      name,
      quantity,
      unit,
      unitPriceWithoutVAT: unitPriceWithoutVAT / 100, // Convert cents in euros
      VATRate,
    })
  ),
})

interface MapDocumentSettingsToDocumentSettingsFormProps {
  documentType: EnumDocumentType
  invoicingSettings: InvoicingSettingsApi
}

export const mapDocumentSettingsToDocumentSettingsForm = ({
  documentType,
  invoicingSettings: {
    displayIBAN,
    estimateAcceptationTerms = '',
    estimateAdditionnalMention = '',
    footer = '',
    invoiceAdditionnalMention = '',
    invoicePaymentTerms = '',
  },
}: MapDocumentSettingsToDocumentSettingsFormProps): DocumentSettingsFormData => {
  const formData = {
    accountType:
      displayIBAN?.type ?? EnumDisplayIbanAccountType.EXTERNAL_ACCOUNT,
    displayIban:
      displayIBAN === null ? displayIBAN : Boolean(displayIBAN?.display),
    footer,
  }
  switch (documentType) {
    case EnumDocumentType.ESTIMATE:
      return {
        ...formData,
        additionalMention: estimateAdditionnalMention,
        terms: estimateAcceptationTerms,
      }
    case EnumDocumentType.INVOICE:
      return {
        ...formData,
        additionalMention: invoiceAdditionnalMention,
        terms: invoicePaymentTerms,
      }
  }
}

export const mapDocumentServiceFormToDocumentItem = ({
  percentageVATRate,
  ...rest
}: DocumentLineFormData): DocumentItemPayload => {
  return {
    VATRate: percentageVATRate ? percentageVATRate / 100 : 0,
    currency: 'EUR',
    ...rest,
  }
}

export const mapDocumentItemToDocumentServiceForm = ({
  currency: _, // The way to omit a property from spread
  VATRate,
  ...rest
}: DocumentItemPayload): DocumentLineFormData => {
  return {
    percentageVATRate: VATRate ? VATRate * 100 : 0,
    ...rest,
  }
}

const delayFieldNames: Record<
  EnumDocumentType,
  keyof Pick<
    PostValidateDocumentPayload,
    'estimateAcceptationDays' | 'invoicePaymentDays'
  >
> = {
  [EnumDocumentType.ESTIMATE]: 'estimateAcceptationDays',
  [EnumDocumentType.INVOICE]: 'invoicePaymentDays',
}

export const mapDocumentValidationFormToPayload = (
  formData: DocumentValidationFormData,
  documentType: EnumDocumentType
): PostValidateDocumentPayload => {
  const delayFieldName = delayFieldNames[documentType]

  return {
    documentNumber: formData.documentNumber,
    validatedAt: dayjs().toISOString(),
    [delayFieldName]: formData.delay,
  }
}

export const getDocumentRedirectionPath = (document: DocumentApi) => {
  const { status, type, id } = document
  if (status === EnumDocumentStatus.DRAFT) {
    return `/facturation/creation/${DOCUMENT_TYPE_SLUGS[type]}?documentId=${id}&isEditMode=true`
  } else {
    return `/facturation/documents/${id}`
  }
}
